<template>
  <div class="activateNewEmailWithToken">
    <!--  Your account has been activated message with button to homepage -->
    <TokenStatus
        operation="ACTIVATE_NEW_EMAIL"
        :token="activateNewEmailToken"
        tokenName="activateNewEmailToken"
        @token-is-valid="() => tokenIsValid=true"
        @token-is-invalid="() => tokenIsValid=false"
        @token-has-expired="() => tokenIsValid=false"
    ></TokenStatus>
    <Transition>
      <form
          v-if="tokenIsValid"
          class="form"
      >
        <div class="items-center justify-center flex flex-col">
          <button class="button primary" @click="$router.push('/')">
            Return to home
          </button>
        </div>
      </form>
    </Transition>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import TokenStatus from "@/components/authentication/TokenStatus.vue";
import {useStatusMessage} from "@/composables/useStatusMessage";
import {ACTIVATE_NEW_EMAIL} from "@/store/operations";
export default {
  name: "ActivateNewEmailWithToken",
  components: {
    TokenStatus,
  },
  props: {
    activateNewEmailToken: {
      type: String,
      required: true
    },
  },
  setup() {
    const tokenIsValid = ref(null)
    const {setMessage, deleteMessage} = useStatusMessage(ACTIVATE_NEW_EMAIL)

    deleteMessage()

    watch(tokenIsValid, value => {
      if (value === true) setMessage({isSuccess: true, body: "Your token is valid."})
      else if (value === false) setMessage({isError: true, body: 'Your token is invalid. It may have been used before or it may have expired. Please try again.'})
    })

    return {
      tokenIsValid,
    }
  }
}
</script>

<style scoped>

</style>